.container {
    height: 100%;
    margin: auto;
}

.login-container {
    max-width: 400px;
    margin: 0 auto;
    padding: 40px;
    background-color: #fff;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
  }

  .login-container h2 {
    text-align: center;
    margin-bottom: 30px;
  }

  .login-container form .form-control {
    height: 50px;
  }

  .login-container form .btn-primary {
    width: 100%;
    height: 50px;
  }

  .login-container form .btn-primary:hover {
    background-color: #007bff;
  }

  .login-container .text-center {
    margin-top: 20px;
  }