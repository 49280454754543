body{
  height: 100vh;
}

#root {
  min-height: 100%;
}

.btn-nova-troca {
  width: 60px;
  height: 60px;
  font-size: 20px;
  position: fixed;
  bottom: 25px;
  right: 25px;
}